import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ListadosService } from '../../auxiliares/servicios/listados.service';
import { WebSocketService } from '../../auxiliares/servicios/websocket';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  constructor(
    private router: Router,
    private webSocketService: WebSocketService,
    private listadosService: ListadosService,
    private storeService: StoreService,
  ) {}

  public logout() {
    this.storeService.removeLoginInfo();
    this.listadosService.borrarCache();
    this.webSocketService.closeWS();
    this.router.navigate(['/login']);
  }
}
